import React, {useState} from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/contact/_hero.scss';

const Hero: React.FC = () => {
    const [status, setStatus] = useState<'sent' | 'error' | 'none'>('none');
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const {name, email, message, subject} = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            subject: subject.value,
            message: message.value,
        };
        await fetch('https://' + process.env.REACT_APP_DOMAIN + '/email.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(details),
        });
        setStatus('sent');
    };
    return (
        <Section id="contact-hero">
            <Padding id="text">
                <h1>
                    Have any questions?
                    <br />
                    Get in touch!
                </h1>
                <div className="detail">
                    <h2>Find us</h2>
                    <p>Head Office, 2-6 Kordeliou, 162 31 Athens, Greece</p>
                </div>
                <div className="detail">
                    <h2>Email us</h2>
                    <p>info@mscon.eu</p>
                </div>
                <div className="detail">
                    <h2>Call us</h2>
                    <p>+30 - 211 40 01 965</p>
                </div>
                <div className="scrollbutton">
                    <p className="tiny">↓</p>
                </div>
            </Padding>
            <Padding id="form">
                <h3>Send us an email</h3>
                <p className="tiny">We will get back to you as soon as possible!</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-wrapper">
                        <div className="input-group">
                            <label>
                                <p className="tiny">Full Name</p>
                                <input id="name" type="text" title="Name" placeholder="Enter your Name" required />
                            </label>
                        </div>
                        <div className="input-group">
                            <label>
                                <p className="tiny">Email Address</p>
                                <input id="email" type="email" title="Email Address" placeholder="Enter your Email address." required />
                            </label>
                        </div>
                        <div className="input-group">
                            <label>
                                <p className="tiny">Subject</p>
                                <input id="subject" type="text" title="Subject" placeholder="Enter the subject of your message" required />
                            </label>
                        </div>
                        <div className="input-group">
                            <label>
                                <p className="tiny">Message</p>
                                <textarea id="message" title="Message" placeholder="Enter your message" required />
                            </label>
                        </div>
                        {status === 'none' && (
                            <div className="input-group">
                                <button type="submit" className="btn">
                                    Send Message →
                                </button>
                            </div>
                        )}
                        {status === 'sent' && (
                            <div className="input-group message">
                                <p className="tiny">Thank you for your message!</p>
                            </div>
                        )}
                        {status === 'error' && (
                            <div className="input-group message error">
                                <p className="tiny">There was an Error!</p>
                            </div>
                        )}
                    </div>
                </form>
            </Padding>
        </Section>
    );
};

export default Hero;
