import React from 'react';
import '../../assets/scss/partials/_header.scss';
import Padding from './Padding';
import useWindowSize from '../../utils/hooks/useWindowSize';
import {Link} from '../../components/Link';
import {SetState} from '../../utils/types/Global';

type HeaderType = {
    children?: React.ReactNode;
    scrolled?: boolean;
    contact?: boolean;
    opened: boolean;
    setOpened: SetState<boolean>;
};

const Header: React.FC<HeaderType> = ({scrolled = false, opened, setOpened, contact = false, children}) => {
    const windowSize = useWindowSize();
    const mobile = windowSize.width <= 1200;
    return (
        <div id="header" className={scrolled || contact ? 'scrolled ease' : 'ease'}>
            <Padding>
                <div className="logo">
                    <Link setOpened={setOpened} to={'/'}>
                        MSC<span>.</span>
                    </Link>
                </div>
                <div className={mobile && opened ? 'pages open' : 'pages'}>
                    <ul>
                        <li>
                            <Link setOpened={setOpened} to={'/'}>
                                <p className="tiny">Home</p>
                            </Link>
                        </li>
                        <li>
                            <Link setOpened={setOpened} to={'/about'}>
                                <p className="tiny">About</p>
                            </Link>
                        </li>
                        <li>
                            <Link setOpened={setOpened} to={'/services'}>
                                <p className="tiny">Services</p>
                            </Link>
                        </li>
                        <li>
                            <Link setOpened={setOpened} to={'/projects'}>
                                <p className="tiny">Projects</p>
                            </Link>
                        </li>
                        <li>
                            <Link setOpened={setOpened} to={'/contact'}>
                                <p className="tiny">Contact</p>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="burger" onClick={() => setOpened(!opened)}>
                    <div className="button">
                        <div className="line" />
                        <div className="line" />
                        <div className="line" />
                    </div>
                </div>
            </Padding>
        </div>
    );
};

export default Header;
