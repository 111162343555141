import React, {useEffect, useState} from 'react';

import Wrapper from '../containers/partials/Wrapper';
import Layout from '../utils/Layout';

import Hero from '../containers/sections/project/Hero';
import Projects from '../containers/sections/project/Carousel';

import Scroll from '../containers/partials/Scroll';
import {Helmet} from 'react-helmet-async';
import {PageType, ProjectParams} from '../utils/types/Global';
import Footer from '../containers/sections/Footer';
import More from '../containers/sections/project/More';
import {useParams} from 'react-router-dom';

const Project: React.FC<PageType> = ({data, scrolled, setScrolled, setOpened, setContact}) => {
    useEffect(() => {
        setContact(false);
    }, [setContact]);

    const params = useParams<ProjectParams>();
    const project = params.project;

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (data.loading === false && data.data) {
            setLoaded(true);
        }
    }, [data]);

    let value = data?.data !== null ? data?.data.find((o: any) => o?.attributes?.URL === project) : null;

    if (project) {
        // const sub = value?.attributes?.Subtitle !== null ? value?.attributes?.Subtitle.replace('\n', ' ') : '';
        const title = value?.attributes?.Title;
        // const img = process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Images?.data?.[0]?.attributes?.formats?.large?.url;
        // const desc = value?.attributes?.Title + ' / ' + value?.attributes?.Subtitle + '. ' + value?.attributes?.Intro;

        return (
            <>
                <Layout>
                    {loaded ? (
                        <Helmet>
                            <title>{title} | MSC</title>
                            {/* <meta name="og:title" content={title} />
                            <meta name="twitter:title" content={title} />
                            <meta name="og:url" content={'https://' + process.env.REACT_APP_DOMAIN + '/project/' + project} />
                            <meta name="og:image" content={img} />
                            <meta name="twitter:image:src" content={img} />
                            <meta name="image" content={img} />
                            <meta name="description" content={desc} />
                            <meta name="og:description" content={desc} />
                            <meta name="twitter:description" content={desc} /> */}
                        </Helmet>
                    ) : (
                        <Helmet>
                            <link rel="preconnect" href={process.env.REACT_APP_API_URL} />
                        </Helmet>
                    )}
                    <Scroll id="project" scrolled={scrolled} setScrolled={setScrolled}>
                        {loaded && (
                            <Wrapper>
                                <Hero value={value} project={project} />
                                <Projects value={value} project={project} />
                                <More setOpened={setOpened} />
                                <Footer />
                            </Wrapper>
                        )}
                    </Scroll>
                </Layout>
            </>
        );
    }
    return null;
};

export default Project;
