import React, {useEffect} from 'react';

import Wrapper from '../containers/partials/Wrapper';
import Layout from '../utils/Layout';

import Scroll from '../containers/partials/Scroll';
import {Helmet} from 'react-helmet-async';
import {PageType} from '../utils/types/Global';
import Footer from '../containers/sections/Footer';
import Hero from '../containers/sections/services/Hero';
import List from '../containers/sections/services/List';
import Contact from '../containers/sections/services/Contact';

const Services: React.FC<PageType> = ({scrolled, setScrolled, setContact, setOpened}) => {
    useEffect(() => {
        setContact(false);
    }, [setContact]);

    return (
        <>
            <Layout>
                <Helmet>
                    <title>Services | MSC</title>
                </Helmet>
                <Scroll id="about-page" scrolled={scrolled} setScrolled={setScrolled}>
                    <Wrapper>
                        <Hero />
                        <List />
                        <Contact setOpened={setOpened} />
                        <Footer />
                    </Wrapper>
                </Scroll>
            </Layout>
        </>
    );
};

export default Services;
