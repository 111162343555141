import React, {MutableRefObject} from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/about/_hero.scss';
import {About1} from '../../../assets/images';
import {Button} from '../../../components/Button';

const Hero: React.FC<{anchor: MutableRefObject<HTMLDivElement>}> = ({anchor}) => {
    return (
        <Section className="hero-section">
            <Padding id="about-hero">
                <div className="background">
                    <img src={About1} alt="Logo" />
                </div>
                <div className="text">
                    <div className="left">
                        <h1>
                            About
                            <br />
                            Us
                        </h1>
                        <h2>Who We Are</h2>
                        <p>Learn more about the company, who we are, what we do, and all of the ways we can help you make the next step.</p>
                        <div className="scrollbutton">
                            <p className="tiny pc">↓</p>
                        </div>
                    </div>
                    <div className="right">
                        <Button
                            type="light"
                            onClick={() => {
                                if (!anchor.current) {
                                    return;
                                }
                                window.scrollTo(0, anchor.current.offsetTop);
                            }}>
                            Learn More
                        </Button>
                    </div>
                </div>
            </Padding>
        </Section>
    );
};

export default Hero;
