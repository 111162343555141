import React from 'react';
import Section from '../../partials/Section';
import '../../../assets/scss/partials/contact/_map.scss';
import {Map2 as MapImg} from '../../../assets/images';

const Map: React.FC = () => {
    return (
        <a href="https://goo.gl/maps/BxjBqm3XboEU652d7" target="_blank" rel="noreferrer">
            <Section className="map-section">
                <div className="background ease">
                    <img src={MapImg} alt="Contact Map Mobile" />
                </div>
                <div className="text">
                    <h2>View The Map →</h2>
                </div>
            </Section>
        </a>
    );
};

export default Map;
