import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/home/_contact.scss';
import {Map, Phone, House, Email} from '../../../assets/images';

const Contact: React.FC = () => {
    return (
        <Section className="contact-section">
            <div className="background">
                <img src={Map} alt="Contact Map" />
            </div>
            <div id="contact">
                <div className="background">
                    <img src={Map} alt="Contact Map Mobile" />
                </div>
                <Padding>
                    <div className="text">
                        <h2>Work with us.</h2>
                        <p>
                            Join the many happy clients that have entrusted us with making their <span>dream</span> a reality.
                        </p>
                        <div className="contact">
                            <a href="https://goo.gl/maps/BxjBqm3XboEU652d7" target="_blank" rel="noreferrer">
                                <h3>
                                    <img src={House} alt="Address" />
                                    2-6 Kordeliou, 162 31 ATH
                                </h3>
                            </a>
                            <a href="tel:+302114001965" target="_blank" rel="noreferrer">
                                <h3>
                                    <img src={Phone} alt="Phone" />
                                    0030 - 211 4001965
                                </h3>
                            </a>
                            <a href="mailto:info@mscon.eu" target="_blank" rel="noreferrer">
                                <h3>
                                    <img src={Email} alt="Email" />
                                    info@mscon.eu
                                </h3>
                            </a>
                        </div>
                    </div>
                </Padding>
            </div>
        </Section>
    );
};

export default Contact;
