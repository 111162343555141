import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/about/_crew.scss';
import {Metsos} from '../../../assets/images';

const Crew: React.FC = () => {
    let list = [Metsos];
    return (
        <Section className="crew-section">
            <Padding id="crew">
                <div className="text">
                    <h2>Meet the Pros</h2>
                    <p className="description">These are the talented people behind Metal Solution Consult, and your partners to success.</p>
                </div>
                <div className="crew">
                    {list.map((value, index) => {
                        return (
                            <div className="pro" key={index}>
                                <div className="photo">
                                    <img className="ease-img" src={value} alt="Project" />
                                </div>
                                <p className="name">Dimitris</p>
                                <p>CEO</p>
                            </div>
                        );
                    })}
                </div>
            </Padding>
        </Section>
    );
};

export default Crew;
