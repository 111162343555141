import React from 'react';
import {Link as RLink, useLocation} from 'react-router-dom';
import {SetState} from '../utils/types/Global';

interface LinkType {
    setOpened: SetState<boolean>;
    to: string;
    children: React.ReactNode;
    [x: string]: any;
}

export const Link: React.FC<LinkType> = ({to, setOpened, children, ...props}) => {
    const location = useLocation();
    return (
        <RLink
            to={to}
            onClick={() => {
                setOpened(false);
                if (location.pathname === to) {
                    window.scrollTo(0, 0);
                } else {
                    setTimeout(() => window.scrollTo(0, 0), 600);
                }
            }}
            {...props}>
            {children}
        </RLink>
    );
};

export const ExternalLink: React.FC<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>> = ({children, ...props}) => {
    return <a {...props}>{children}</a>;
};
