import React from 'react';
import Section from '../../partials/Section';
import '../../../assets/scss/partials/contact/_map.scss';
import {Link} from '../../../components/Link';
import {SetState} from '../../../utils/types/Global';

const More: React.FC<{setOpened: SetState<boolean>}> = ({setOpened}) => {
    return (
        <Link setOpened={setOpened} to={'/projects'}>
            <Section className="map-section more">
                <div className="background ease"></div>
                <div className="text">
                    <h2>More Projects →</h2>
                </div>
            </Section>
        </Link>
    );
};

export default More;
