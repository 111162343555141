import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/services/_list.scss';
// import {Button} from '../../../components/Button';
import {Link} from '../../../components/Link';
import {SetState} from '../../../utils/types/Global';

const List: React.FC<{data: any; setOpened: SetState<boolean>}> = ({data, setOpened}) => {
    return (
        <Section className="services-section">
            <Padding id="projects">
                <div className="services">
                    <ul>
                        {data?.map((value: any, index: number) => {
                            return (
                                <li key={index}>
                                    <Link setOpened={setOpened} to={'/project/' + value?.attributes?.URL}>
                                        <div className="image">
                                            <img
                                                src={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Images?.data?.[0]?.attributes?.formats?.large?.url}
                                                className="ease-img"
                                                alt={value?.attributes?.Title + ' Project Image'}
                                            />
                                        </div>
                                        <div className="text">
                                            <h2 className="title">{value?.attributes?.Title}</h2>
                                            <p className="description">{value?.attributes?.Subtitle}</p>
                                        </div>
                                    </Link>
                                </li>
                            );
                        })}
                        {/* <Button type="light">View More</Button> */}
                    </ul>
                </div>
            </Padding>
        </Section>
    );
};

export default List;
