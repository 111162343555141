import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/home/_projects.scss';
import {Link} from '../../../components/Link';
import {SetState} from '../../../utils/types/Global';
import {Button} from '../../../components/Button';

const Projects: React.FC<{data: any; setOpened: SetState<boolean>}> = ({data, setOpened}) => {
    return (
        <Section className="projects-section">
            <Padding id="projects">
                <div className="text">
                    <h2>Our Projects</h2>
                    <p>
                        We work closely with our global partners, to understand unique project needs and develop <span>tailored proposals</span> to specific market demands.
                    </p>
                </div>
                <div className="projects">
                    <ul>
                        {data?.slice(0, 3).map((value: any, index: number) => {
                            return (
                                <li key={index}>
                                    <Link setOpened={setOpened} to={'/project/' + value?.attributes?.URL}>
                                        <img
                                            src={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Images?.data?.[0]?.attributes?.formats?.large?.url}
                                            className="ease-img"
                                            alt={value?.attributes?.Title + ' Project Image'}
                                        />
                                    </Link>
                                    <p className="tiny">{value?.attributes?.Title}</p>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="more">
                        <Link setOpened={setOpened} to="/projects">
                            <Button type="dark-set" className="full">
                                View More
                            </Button>
                        </Link>
                    </div>
                </div>
            </Padding>
        </Section>
    );
};

export default Projects;
