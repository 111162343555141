import React, {useEffect, useRef, MutableRefObject} from 'react';

import Wrapper from '../containers/partials/Wrapper';
import Layout from '../utils/Layout';

import Scroll from '../containers/partials/Scroll';
import {Helmet} from 'react-helmet-async';
import {PageType} from '../utils/types/Global';
import Footer from '../containers/sections/Footer';
import Hero from '../containers/sections/about/Hero';
import Details from '../containers/sections/about/Details';
import Crew from '../containers/sections/about/Crew';

const About: React.FC<PageType> = ({scrolled, setScrolled, setContact}) => {
    const AboutAnchor = useRef() as MutableRefObject<HTMLDivElement>;

    useEffect(() => {
        setContact(false);
    }, [setContact]);

    return (
        <>
            <Layout>
                <Helmet>
                    <title>About | MSC</title>
                </Helmet>
                <Scroll id="about-page" scrolled={scrolled} setScrolled={setScrolled}>
                    <Wrapper>
                        <Hero anchor={AboutAnchor} />
                        <Details anchor={AboutAnchor} />
                        <Crew />
                        <Footer />
                    </Wrapper>
                </Scroll>
            </Layout>
        </>
    );
};

export default About;
