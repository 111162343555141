import React, {useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';

import Home from './pages/Home';
import Header from './containers/partials/Header';
import {HelmetProvider} from 'react-helmet-async';
import About from './pages/About';
import Projects from './pages/Projects';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Project from './pages/Project';
import useFetchData from './utils/hooks/useFetchData';

const App = () => {
    const location = useLocation();
    const [scrolled, setScrolled] = useState(false);
    const [contact, setContact] = useState(false);
    const [opened, setOpened] = useState(false);

    const {data, loading, error} = useFetchData(`/projects?populate=*`);

    return (
        <HelmetProvider>
            <Header scrolled={scrolled} opened={opened} setOpened={setOpened} contact={contact} />
            <AnimatePresence exitBeforeEnter>
                <Routes location={location} key={location.pathname}>
                    <Route
                        path="/project/:project"
                        element={
                            <Project data={{data, loading, error}} opened={opened} setOpened={setOpened} scrolled={scrolled} setScrolled={setScrolled} setContact={setContact} />
                        }
                    />
                    <Route
                        path="/about"
                        element={
                            <About data={{data, loading, error}} opened={opened} setOpened={setOpened} scrolled={scrolled} setScrolled={setScrolled} setContact={setContact} />
                        }
                    />
                    <Route
                        path="/projects"
                        element={
                            <Projects data={{data, loading, error}} opened={opened} setOpened={setOpened} scrolled={scrolled} setScrolled={setScrolled} setContact={setContact} />
                        }
                    />
                    <Route
                        path="/services"
                        element={
                            <Services data={{data, loading, error}} opened={opened} setOpened={setOpened} scrolled={scrolled} setScrolled={setScrolled} setContact={setContact} />
                        }
                    />
                    <Route
                        path="/contact"
                        element={
                            <Contact data={{data, loading, error}} opened={opened} setOpened={setOpened} scrolled={scrolled} setScrolled={setScrolled} setContact={setContact} />
                        }
                    />
                    <Route
                        path="*"
                        element={<Home data={{data, loading, error}} opened={opened} setOpened={setOpened} scrolled={scrolled} setScrolled={setScrolled} setContact={setContact} />}
                    />
                </Routes>
            </AnimatePresence>
        </HelmetProvider>
    );
};

export default App;
