import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/project/_carousel.scss';
import {ProjectParams} from '../../../utils/types/Global';

interface CarouselType extends ProjectParams {
    value: any;
}

const Carousel: React.FC<CarouselType> = ({project, value}) => {
    return (
        <Section className="carousel-section">
            <Padding id="carousel">
                <div className="text">
                    <h2>Project Gallery</h2>
                    <p>Get a preview of the work we completed.</p>
                </div>
                <div className="images">
                    <ul>
                        {value?.attributes?.Images?.data.map((value: any, index: number) => {
                            return (
                                <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_UPLOAD_URL + value.attributes?.url} title={'Project Image' + index + ' Link'}>
                                    <li key={index}>
                                        <img src={process.env.REACT_APP_UPLOAD_URL + value.attributes?.formats?.large?.url} className="ease-img" alt={'Project Image ' + index} />
                                    </li>
                                </a>
                            );
                        })}
                    </ul>
                </div>
            </Padding>
        </Section>
    );
};

export default Carousel;
