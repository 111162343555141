import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/contact/_jobs.scss';

const Jobs: React.FC = () => {
    return (
        <Section className="jobs-section">
            <Padding id="jobs">
                <div className="text">
                    <h2>
                        Looking for a new
                        <br />
                        challenge?
                    </h2>
                    <p>
                        As one of the world’s leading construction groups, MSC offers outstanding career prospects with great potential. We provide advanced training to further
                        develop your individual strengths. At construction sites, in the office, in Greece or abroad: The world of MSC is as varied as the assignments it gives to
                        its employees.
                    </p>
                    <p>
                        Whether you’re a technical or commercial professional, your expertise will help us develop successfully. We’re looking for qualified personnel for
                        challenging projects. MSC offers excellent career perspectives for, among others, construction and supply engineers, architects, economic scientists and
                        business engineers as well as commercial and technical staff.
                    </p>
                    <p>
                        Please apply by sending us your CV to{' '}
                        <a href="mailto:hr@mscon.eu">
                            <span>hr@mscon.eu</span>
                        </a>
                    </p>
                </div>
            </Padding>
        </Section>
    );
};

export default Jobs;
