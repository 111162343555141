import React from 'react';

type ButtonType = {
    type: 'light' | 'dark' | 'dark-set';
    onClick?: () => void;
    className?: string;
};

export const Button: React.FC<ButtonType> = ({children, type = 'dark', className, onClick}) => {
    return (
        <div onClick={onClick} className={'btn ease ' + type + '-btn' + (className ? ' ' + className : '')}>
            {children}
        </div>
    );
};
