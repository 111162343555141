import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/home/_hero.scss';
import {Banner} from '../../../assets/images';

const Hero: React.FC = () => {
    return (
        <Section className="hero-section">
            <Padding id="hero">
                <div className="text">
                    <h1>
                        Quality
                        <br />
                        <span>steel</span> detailing
                        <br />
                        and design.
                    </h1>
                    <p>High level engineering services in structural analysis, steel detailing and shop drawings, 3D modelling and BIM</p>
                    <div className="scrollbutton">
                        <p className="tiny">↓</p>
                        <a className="banner" href={'/VIMA_WEB3b.pdf'} target="_blank" rel="noreferrer">
                            <img src={Banner} alt="Logo" />
                        </a>
                    </div>
                </div>
            </Padding>
        </Section>
    );
};

export default Hero;
