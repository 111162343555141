// import React, {MutableRefObject, useRef} from 'react';
import React, {useCallback, useEffect} from 'react';

import {motion} from 'framer-motion';
import {pageTransitionDelay} from '../../utils/data/Global';
import {SetState} from '../../utils/types/Global';

interface ScrollType {
    id: string;
    children: React.ReactNode;
    scrolled: boolean;
    setScrolled: SetState<boolean>;
}

const Scroll: React.FC<ScrollType> = ({id, children, scrolled, setScrolled}) => {
    const smoothScrollingHandler = useCallback(() => {
        if (window.scrollY === 0) {
            if (scrolled) {
                setScrolled(false);
            }
        } else {
            if (!scrolled) {
                setScrolled(true);
            }
        }

        requestAnimationFrame(() => smoothScrollingHandler());
    }, [scrolled, setScrolled]);

    useEffect(() => {
        requestAnimationFrame(() => smoothScrollingHandler());
    }, [smoothScrollingHandler]);

    return (
        <motion.div className="scroll-html" transition={pageTransitionDelay} initial={{pointerEvents: 'none'}} exit={{pointerEvents: 'none'}} animate={{pointerEvents: 'all'}}>
            {children}
        </motion.div>
    );
};

export default Scroll;
