import React from 'react';

type PaddingType = {
    id?: string | undefined;
    children: React.ReactNode;
};

const Padding: React.FC<PaddingType> = ({children, id = undefined}) => {
    return (
        <div id={id} className="padding">
            {children}
        </div>
    );
};

export default Padding;
