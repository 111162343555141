import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/home/_partners.scss';
import {Tekla, Idea, Etabs, Bentley} from '../../../assets/images';

const Partners: React.FC = () => {
    let list = [Tekla, Idea, Etabs, Bentley];
    return (
        <Section className="partners-section">
            <Padding id="partners">
                <div className="text">
                    <h3>Trusted By</h3>
                </div>
                <div className="partners">
                    {list.map((value, index) => {
                        return (
                            <div className="partner ease-img" key={index}>
                                <img src={value} alt="Project" />
                            </div>
                        );
                    })}
                </div>
            </Padding>
        </Section>
    );
};

export default Partners;
