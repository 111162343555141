import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';

import App from './App';

import './assets/scss/global.scss';
import './assets/scss/fonts.scss';
const basename = document.querySelector('base')?.getAttribute('href') ?? '/';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={basename}>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);
