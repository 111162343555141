import React from 'react';
import Section from '../partials/Section';
import Padding from '../partials/Padding';
import '../../assets/scss/partials/_footer.scss';

const Footer: React.FC = () => {
    return (
        <Section className="footer-section">
            <Padding id="footer">
                <div className="text">
                    <p>© Metal Solution Consult - All Rights Reserved</p>
                </div>
            </Padding>
        </Section>
    );
};

export default Footer;
