import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/services/_list.scss';
import {analysis, concrete, design, detailing, estimates, modeling} from '../../../assets/images';

const List: React.FC = () => {
    let list = [
        [
            detailing,
            'Steel Detailing',
            'We specialize in producing detailed designs (shop drawings, steel detailing) of metal or composite buildings of any coefficient of difficulty and complexity. We have successfully completed constructions of industrial buildings, toll plazas, public property buildings, shopping malls, sports facilities, homes, shelters and bridges. An integral part of business of the company is the technical support from the beginning to the end of construction.\n\nFurther detailed production designs include: Part Drawings, Assembly Drawings, Erection Drawings, Material list, CNC - DSTV - IFC - FEM files. The whole process is fulfilled through the most specialized software of design, Tekla Structures.',
        ],
        [
            modeling,
            '3D Modeling',
            'We undertake the three-dimensional (3D) modelling of any steel and composite construction. This results in a complete 3D project display for you and your customers. The cooperation and the avoidance of mistakes between the manufacturer and the engineer or between the contractor and the customer, especially on complex structures, are secured in the maximum level.\n\nWe provide a file that includes the construction of three-dimensional form, where the user can see the final version of the project, the connections in detail, the properties (hardness, any special dye) and other materials. It also provides the ability the user to intervene and note any change that has occurred in the project and point it to the sender for the necessary changes.',
        ],
        [
            design,
            'Connection Design',
            'We provide full structural steel connection design services for structural steel fabricators, building owners, construction managers and general contractors. Having a specialized connection design team with fabrication and erection experience enhances our understanding of how structures are designed, fabricated and erected, and enables us to design structurally efficient connections.\n\nWe have developed several design tools that enable us to evaluate multiple connection alternatives to determine the most efficient, economical and safe connections. We are well versed in Tekla modeling and use this software for particularly difficult or complex connections, ensuring that the connections can be fabricated and easily erected in the field.',
        ],
        [
            concrete,
            'RC Detailing',
            'MSC is a leading provider of reinforced concrete detailing services to the construction industry.\n\nOur team of qualified engineers and technicians can provide data-driven reinforced concrete designs for upcoming projects, helping you save time and revenue while maintaining highest quality and compliance with industry construction standards.',
        ],
        [
            estimates,
            '2D Designs & Estimates',
            'We undertake any form of two-dimensional design of steel or composite construction projects. Our job mainly focuses on schemes foundation, coating, special design items and gutters. The drawings produced include the geometry, dimensions, quantity, color and quality of special items of construction and the complete designs of construction in general. Specifically: Foundation Drawing, Covering Drawing, Special items Drawing, Gutter Drawing\n\nWe also undertake the estimate of quantities of the project, delivering detailed reports of the materials necessary for the project so that our clients can have the most accurate picture of the pre-costing that he will do in order to give the closest possible offer for the award, in order to avoid any over-costing or down-costing of the project.',
        ],
        [
            analysis,
            'Structural Analysis',
            'We undertake the structural design of metal and composite constructions fulfilled by experienced and qualified engineers of our design department who are familiar with international rules. Everything is studied with respect to the safety, the functionality and maximum budget performance of projects undertaken. The international rules that we use are:\n\nEuropean Standards: British Standard Codes for steel detailing (BS), Euro Codes\n\nUS Standards: AISC, ASCE, ASTM, NDS, Steel Erectors Association of USA, Steel Joist Institute, Steel Deck Institute, American Galvanizers Association, American Welding Society',
        ],
    ];
    return (
        <Section className="services-section">
            <Padding id="services">
                <div className="services">
                    <ul>
                        {list.map((value, index) => {
                            return (
                                <li key={index} className={index % 2 !== 0 ? 'right' : ''}>
                                    <div className="image">
                                        <img src={value[0]} className="ease-img" alt="Project" />
                                    </div>
                                    <div className="text">
                                        <h2 className="title">{value[1]}</h2>
                                        <p className="description">{value[2]}</p>
                                    </div>
                                    <div className="line" />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </Padding>
        </Section>
    );
};

export default List;
