import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/project/_hero.scss';
import {ProjectParams} from '../../../utils/types/Global';

interface HeroType extends ProjectParams {
    value: any;
}
const Hero: React.FC<HeroType> = ({project, value}) => {
    return (
        <Section className="hero-section">
            <Padding id="project-hero">
                <div className="text">
                    <h1>{value?.attributes?.Title}</h1>
                    <p className="tiny">{value?.attributes?.Subtitle}</p>
                    <div className="scrollbutton">
                        <p>↓</p>
                    </div>
                </div>

                <div className="image">
                    <img
                        src={process.env.REACT_APP_UPLOAD_URL + value?.attributes?.Images?.data?.[0]?.attributes?.formats?.large?.url}
                        alt={value?.attributes?.Title + ' Project Image'}
                    />
                </div>
            </Padding>
        </Section>
    );
};

export default Hero;
