import React from 'react';
import Section from '../../partials/Section';
import '../../../assets/scss/partials/services/_hero.scss';
import {Services} from '../../../assets/images';

const Hero: React.FC = () => {
    return (
        <Section className="hero-section">
            <div id="services-hero">
                <div className="background">
                    <img src={Services} alt="Logo" />
                </div>
                <div className="text">
                    <div className="left">
                        <h1>Our Services</h1>
                        <p>View all of our services and learn more about what we do.</p>
                    </div>
                    <div className="right">
                        <div className="scrollbutton">
                            <p>↓</p>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default Hero;
