import React, {useEffect} from 'react';

import Wrapper from '../containers/partials/Wrapper';
import Layout from '../utils/Layout';

import Scroll from '../containers/partials/Scroll';
import {Helmet} from 'react-helmet-async';
import {PageType} from '../utils/types/Global';
import Footer from '../containers/sections/Footer';
import Hero from '../containers/sections/contact/Hero';
import Map from '../containers/sections/contact/Map';
import Jobs from '../containers/sections/contact/Jobs';

const Contact: React.FC<PageType> = ({scrolled, setScrolled, setContact}) => {
    useEffect(() => {
        setContact(true);
    }, [setContact]);

    return (
        <>
            <Layout>
                <Helmet>
                    <title>Contact | MSC</title>
                </Helmet>
                <Scroll id="about-page" scrolled={scrolled} setScrolled={setScrolled}>
                    <Wrapper>
                        <Hero />
                        <Map />
                        <Jobs />
                        <Footer />
                    </Wrapper>
                </Scroll>
            </Layout>
        </>
    );
};

export default Contact;
