import React, {MutableRefObject} from 'react';
import {motion} from 'framer-motion';
import {pageTransition} from '../../utils/data/Global';

type SectionType = {
    children?: React.ReactNode;
    className?: string;
    id?: string;
    innerRef?: MutableRefObject<HTMLDivElement>;
};

const Section: React.FC<SectionType> = ({children, id = undefined, innerRef = undefined, className = ''}) => {
    return (
        <motion.div ref={innerRef} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={pageTransition} id={id} className={className}>
            {children}
        </motion.div>
    );
};

export default Section;
