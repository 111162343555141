import React, {MutableRefObject} from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/about/_hero.scss';
import {About2} from '../../../assets/images';

const About: React.FC<{anchor: MutableRefObject<HTMLDivElement>}> = ({anchor}) => {
    return (
        <Section className="hero-section" innerRef={anchor}>
            <Padding id="about-details">
                <div className="background">
                    <img src={About2} alt="Logo" />
                </div>
                <div className="text">
                    <div className="left">
                        <h2>
                            State of the art
                            <br />
                            steel infrastructure
                        </h2>
                        <p>
                            Our head office is in Athens, Greece and our expertise and state-of-the art IT infrastructure and AEC software is a reliable environment for on-time
                            project deliverables of high quality.
                        </p>
                        <p>
                            During the last 15 years we have achieved an extended portfolio of delivered projects ranging from industrial halls up to complex industrial
                            installations.
                        </p>
                        <h3>Our main principles:</h3>
                        <p>
                            - Adaptation on any project requirement
                            <br />- Promptness on customer calls and requests
                            <br />- Strict compliance with time schedules
                            <br />- Privacy and discretion
                        </p>
                    </div>
                </div>
            </Padding>
        </Section>
    );
};

export default About;
