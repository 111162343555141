import React from 'react';
import Section from '../../partials/Section';
import Padding from '../../partials/Padding';
import '../../../assets/scss/partials/home/_about.scss';
import {Logo} from '../../../assets/images';
import {Link} from '../../../components/Link';
import {SetState} from '../../../utils/types/Global';

const About: React.FC<{setOpened: SetState<boolean>}> = ({setOpened}) => {
    return (
        <Section className="about-section">
            <img src={Logo} alt="Logo" width={2668} height={2607} />
            <Padding id="about">
                <div className="text">
                    <div className="right">
                        <h2>
                            About Metal
                            <br />
                            Solution Consult
                        </h2>
                        <p>
                            We are a creative company based in Athens, <span>Greece</span> and our expertise and state-of-the art IT infrastructure and AEC software is a reliable
                            environment for on-time project deliverables of high quality.
                        </p>
                        <p>
                            <Link setOpened={setOpened} to="/about">
                                <strong>Read More →</strong>
                            </Link>
                        </p>
                    </div>
                </div>
            </Padding>
        </Section>
    );
};

export default About;
