import React from 'react';

type LayoutType = {
    children: React.ReactNode;
};

const Layout: React.FC<LayoutType> = ({children}) => {
    return <React.Fragment>{children}</React.Fragment>;
};

export default Layout;
